import { FamilyMenuDay } from './menu-types';
import { User } from './user-types';
/**
 * Class that implements {@link IDetailedFamily} interface.
 * Similar to {@link IFamily} entity, but it will be used for frontend cases.
 * Instead of having just IDs in family members, it will have user entities.
 * Do not save this model in DB!
 *
 * When used, make sure that you are using the class, not the interface. When
 * a class get serialised, like when using API requests, it looses its class
 * properties and becomes a plain javascript object.
 */
export class DetailedFamily {
  constructor(obj) {
    this.familyAdmins = [];
    this.shoppingList = [];
    this.id = obj.id;
    this.familyMembers = obj.familyMembers.map(fm => new User(fm));
    this.familyAdmins = obj.familyAdmins;
    this.schedule = obj.schedule?.map(s => new FamilyMenuDay(s));
    this.shoppingList = obj.shoppingList ?? [];
  }
  get quickMenuDay() {
    return this.schedule?.filter(r => r.day === 'quick')[0];
  }
  get menuDays() {
    return this.schedule?.filter(r => r.day !== 'quick');
  }
  getScheduleRecipe(day, meal) {
    const found = this.schedule?.find(r => day === 'quick' && r.day === day || day !== 'quick' && Number(r.day) === Number(day));
    return found ? found[meal] ?? null : null;
  }
}
