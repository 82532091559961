/**
 * All entries of {@link TSexes}
 */
export const Sexes = ['female', 'male'];
/**
 * All entries of {@link TFoodRestriction}
 */
export const FoodRestrictions = ['foodDiets', 'allergies', 'intolerances', 'dislikes'];
/**
 * All entries of {@link TFoodDiet}
 */
export const FoodDiets = ['flexitarian', 'pescetarian', 'vegan', 'veganKeto', 'vegetarian', 'vegetarianKeto'];
/**
 * All entries of {@link TAllergy}
 */
export const Allergies = ['celery', 'cereals', 'crustacean', 'egg', 'fish', 'milk', 'nut', 'lupin', 'mollusc', 'mustard', 'peanutSep', 'shellfish', 'sesameSeed', 'soy', 'sulfates', 'wheat'];
/**
 * All entries of {@link TIntolerance}
 */
export const Intolerances = ['glutenIntolerance', 'glutenSensitivity', 'histamineIntolerance', 'lactoseIntolerance'];
/**
 * Class that implements {@link IUser} interface.
 * Application user, typically considered as family member.
 *
 * When used, make sure that you are using the class, not the interface. When
 * a class get serialised, like when using API requests, it looses its class
 * properties and becomes a plain javascript object.
 */
export class User {
  constructor(input = {}) {
    this.id = '';
    this.sub = '';
    this.familyId = '';
    this.name = '';
    this.phoneNumber = '';
    this.email = '';
    this.settings = {
      sex: null,
      age: null,
      activityLevel: null,
      weight: null,
      height: null,
      foodDiets: [],
      allergies: [],
      intolerances: [],
      dislikes: []
    };
    this.statistics = {
      creationTimestamp: 0
    };
    // Copy input object, otherwise, when doing "delete" if someone still uses
    // that "input" variable it will be broken
    const inputCopy = JSON.parse(JSON.stringify(input));
    // Assign first object-properties so it uses default values for unset values
    Object.assign(this.settings, inputCopy.settings);
    Object.assign(this.statistics, inputCopy.statistics);
    // Now remove those object-properties from input and assign the rest
    delete inputCopy.settings;
    delete inputCopy.statistics;
    Object.assign(this, inputCopy);
  }
  get isValid() {
    return !!this.name && !!this.settings.sex && !!this.settings.age && !!this.settings.activityLevel && !!this.settings.weight && !!this.settings.height;
  }
}
