import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {Router} from '@angular/router'
import {IUser} from '@ellen/user-be'
import {BehaviorSubject, filter, Observable} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {ACCESS_TOKEN_NAME} from '../lib/private-types'
import {UserService} from './user.service'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Signal-flag that marks if the app is doing its initial loading or not
   */
  public isLoading$: WritableSignal<boolean> = signal(false)
  /**
   * Signal that keeps track of the current logged user. It also can be null,
   * meaning there is no logged user.
   */
  public loggedInUser$: WritableSignal<IUser | null> = signal(null)
  /**
   * Signal that keeps track if logged-in user is a family admin.
   */
  public isFamilyAdmin$: WritableSignal<boolean> = signal(false)

  /**
   * Observable that keeps track of the access token once a user is logged in.
   * If nobody is logged in, its value will be null.
   */
  public accessToken$: Observable<string | null>

  /**
   * Behaviour subject related to observable {@link accessToken$}
   * @private
   */
  private pAccessToken$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null)

  private ils: Storage = inject(LOCAL_STORAGE)
  private router: Router = inject(Router)
  private userService: UserService = inject(UserService)

  constructor() {
    this.accessToken$ = this.pAccessToken$.asObservable()

    // Subscribe to any access token changes.
    // If it contains information, it will recover the logged-in user
    this.pAccessToken$
      .pipe(filter(Boolean))
      .subscribe((accessToken: string) => {
        // Whenever a new token arrives means that user just logged in. In that
        // case, we start loading
        this.isLoading$.set(true)

        // Decode token, obtain embedded user and save it.
        const decodedPayload = this.decodeToken(accessToken)
        this.loggedInUser$.set(JSON.parse(decodedPayload))

        // Set if user is a family admin
        this.isFamilyAdmin$.set(true)

        // Save access token in local storage
        this.ils.setItem(ACCESS_TOKEN_NAME, accessToken)

        // Get all users/family information
        this.userService.getSelf()
          .subscribe((family) => {
            this.isLoading$.set(false)
            // Set "me" user, which represents logged-in user inside the family
            this.userService.me$.set(family.familyMembers
              .find(u => u.sub === this.loggedInUser$()?.sub)!)
          })
      })
  }

  /**
   * Method that should be called as APP_INITIALIZER that will check if there
   * is an access token already stored in local storage, which will prevent
   * the need to re-login to the user. It's automatically logged in.
   * This is good to be done at the very beginning of the app, before anything
   * else.
   */
  public bootstrap() {
    const accessToken = this.ils.getItem(ACCESS_TOKEN_NAME) as string
    this.pAccessToken$.next(accessToken)
  }

  /**
   * Sets access token in the app which will trigger an event to save it
   * in local storage. It will also recover the embedded user.
   * Also, it will trigger the first API call to recover "self" information.
   * @param accessToken
   */
  public login(accessToken: string) {
    this.pAccessToken$.next(accessToken)
  }

  /**
   * Logout method that can be called manually by user, or automatically when
   * a 401/403 response is received from API. This last part is done by
   * response.interceptor.ts.
   * All login-related data, like access token or current logged user, will be
   * reset and user will be redirected to initial page.
   */
  public logout() {
    this.ils.removeItem(ACCESS_TOKEN_NAME)
    this.pAccessToken$.next(null)
    this.loggedInUser$.set(null)
    this.userService.reset()
    this.router.navigate(['/']).then()
  }

  /**
   * Decodes token using UTF-8 encoding to be able to receive "weird" characters
   * from names/lastnames and returns it as "stringify-ed" payload.
   * @param token User's access token
   * @returns Decoded "stringify-ed" payload
   */
  private decodeToken(token: string): string {
    const binaryString = atob(token.split('.')[1])
    const bytes = new Uint8Array(binaryString.length)

    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new TextDecoder('utf-8').decode(bytes)
  }
}