import {TMode} from './public-types'

/**
 * All modes that BankID can go to, which include all public ones and some
 * more.
 */
export type TPrivateMode =
  'idle'
  | 'loading'
  | 'opening_app'
  | 'completed'
  | TMode

/**
 * Possible statuses returned by {@link Collector}
 */
export type TCollectStatus = 'ok' | 'error' | 'cancel' | 'pending' | 'signing'

export interface CollectResponse {
  /**
   * The order reference
   */
  orderRef: string

  /**
   * The status of the order
   */
  status: string

  /**
   * A hint of what is happening, it could be good things or bad things
   */
  hintCode: string

  /**
   * The access token, in form of a jwt to use for further access
   */
  accessToken: string

  /**
   * Present if something is broken
   */
  errorCode: string

  /**
   * Present if 30+ seconds have passed and a new order reference has started
   * under the hood.
   */
  autoStartToken?: string
}

export interface IStartRequestResponse {
  /**
   *
   * Used to collect the status of the order
   */
  orderRef: string
  /**
   * Used to compile the start url
   */
  autoStartToken: string
  /**
   * Used to compute the animated QR code. String.
   */
  qrStartToken: string
}

export interface IStartRequestInput {
  /**
   * This is pretty pointless but it
   * is stored. It is set by the backend
   */
  account_number?: string

  /**
   * Domain, must be included from now on. This is
   * equivalent to an "aud" property.
   */
  domain: string
  /**
   * IF present it will be put in requirements
   * and only the person with this sub
   */
  personNummer?: string

  /**
   * Type is if you want to start a sign
   * session. If so the userVisible etc. must
   * be set as well
   */
  type: TStartRequestType

  /**
   * What the user see in the sign request
   */
  userVisibleData?: string

  /**
   * What is _actually_ signed.
   */
  userNonVisibleData?: string

  /**
   * If set the user visible data
   * will be treated as Markdown.
   */
  userVisibleDataFormat?: 'simpleMarkdownV1'
}

export type TStartRequestType = 'sign' | 'auth'

export interface IStartRequestResponse {
  /**
   *
   * Used to collect the status of the order
   */
  orderRef: string
  /**
   * Used to compile the start url
   */
  autoStartToken: string
  /**
   * Used to compute the animated QR code. String.
   */
  qrStartToken: string
}

export const ACCESS_TOKEN_NAME = 'joshu-token'