import {HttpClient} from '@angular/common/http'
import {inject, Injectable, signal, WritableSignal} from '@angular/core'
import {
  DetailedFamily,
  FamilyMenuDay,
  IDetailedFamily,
  TShoppingList,
  User
} from '@ellen/user-be'
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  map,
  NEVER,
  Observable,
  tap
} from 'rxjs'
import {environment} from '../../environments/environment'
import {CREATING_USER_ID} from '../application/constants'

@Injectable({
  providedIn: 'root'
})

export class UserService {
  /**
   * Signal-flag to mark when a user is being updated. It can be used for nice
   * "placeholder-ing" and prettiness.
   */
  public isUpdatingUser$: WritableSignal<string | null> = signal(null)
  /**
   * Signal-flag to mark when family is being updated
   */
  public isUpdatingFamily$: WritableSignal<boolean> = signal(false)
  /**
   * Signal that keeps family members for easier access, since we use them a lot
   */
  public familyMembers$: WritableSignal<User[]> = signal([])
  /**
   * Signal that represents logged-in user as family member.
   * This should be set once user logs in from {@link ConfigService}
   */
  public me$: WritableSignal<User> = signal(new User())

  /**
   * Observable that keeps track of currently logged family
   */
  public family$: Observable<DetailedFamily | null>

  /**
   * Observable that keeps track of currently selected user.
   */
  public selectedUser$: Observable<User>

  private pFamily$: BehaviorSubject<DetailedFamily | null> =
    new BehaviorSubject<DetailedFamily | null>(null)

  private pSelectedUser$: BehaviorSubject<User> =
    new BehaviorSubject<User>(new User())

  private httpClient: HttpClient = inject(HttpClient)

  constructor() {
    // Initialise public observables
    this.selectedUser$ = this.pSelectedUser$.asObservable()
    this.family$ = this.pFamily$.asObservable()

    // With every event that we receive with a new family, we update the signal
    // that contains all family members
    this.family$.subscribe((fam) =>
      this.familyMembers$.set(fam ? fam.familyMembers : []))
  }

  /**
   * Method that should be called whenever the user logs out.
   * It's useful mainly when user is logged out because 401 or 403.
   */
  public reset() {
    this.pSelectedUser$.next(new User())
    this.me$.set(new User())
    this.pFamily$.next(null)
    this.isUpdatingUser$.set(null)
  }

  public getSelf(): Observable<DetailedFamily> {
    const url = `${environment.apiUrl}/user/self`

    return this.httpClient.get<IDetailedFamily>(url).pipe(
      // Map interface to class
      map((f: IDetailedFamily) => new DetailedFamily(f)),
      tap((family: DetailedFamily) => {
        // Selected user should always be "me" when we recover "self"
        // But for now, it will be first member inside family
        this.pSelectedUser$.next(family.familyMembers[0])
        this.pFamily$.next(family)
      })
    )
  }

  public closeAccount(): Observable<void> {
    const url = `${environment.apiUrl}/user/self`

    return this.httpClient.delete<void>(url)
  }

  public saveUser(user: User): Observable<void> {
    this.isUpdatingUser$.set(user.id)
    const url = `${environment.apiUrl}/user/${user.id}`
    return this.httpClient.put<void>(url, user)
      .pipe(
        tap(() => {
          // Make sure to update user once an OK is received from BE
          this.familyMembers$()
            .filter(u => u.id === user.id)
            .forEach(u => Object.assign(u, user))
          // Stop loader
          this.isUpdatingUser$.set(null)
        })
      )
  }

  public addUser(user: User): Observable<DetailedFamily> {
    // Add a random user's ID now to make signal work.
    // It will be replaced once it is created for real.
    user.id = CREATING_USER_ID
    this.isUpdatingUser$.set(user.id)

    // Add user to current family
    this.family$
      .pipe(filter(Boolean), first())
      .subscribe(familyToUpdate => {
        familyToUpdate.familyMembers.push(user)
        this.pFamily$.next(familyToUpdate)
      })

    // Select new user so UI moves to that user in My Family.
    // It will basically will show a loader but with new user's face selected
    this.pSelectedUser$.next(user)

    const url = `${environment.apiUrl}/family/member`
    return this.httpClient.put<IDetailedFamily>(url, user)
      .pipe(
        map((f: IDetailedFamily) => new DetailedFamily(f)),
        tap((family: DetailedFamily) => {
          // Save new family
          this.pFamily$.next(family)
          // Select created user so setting up can continue smoothly.
          // This time it will have a real ID.
          this.pSelectedUser$.next(family.familyMembers[family.familyMembers.length - 1])
          this.isUpdatingUser$.set(null)
        })
      )
  }

  public removeFromFamily(user: User): Observable<DetailedFamily> {
    // Switch to "me" user when removing a member
    this.switchUser(this.me$().id)
    // Start loader in user to be removed
    this.isUpdatingUser$.set(user.id)

    const url: string = `${environment.apiUrl}/family/member/${user.id}`
    return this.httpClient.delete<IDetailedFamily>(url)
      .pipe(
        // Map interface to class
        map((f: IDetailedFamily) => new DetailedFamily(f)),
        // Stop loading & set users
        tap((family) => {
          this.isUpdatingUser$.set(null)
          this.pFamily$.next(family)
        })
      )
  }

  public saveFamilyShoppingList(newShoppingList: TShoppingList): Observable<TShoppingList> {
    this.isUpdatingFamily$.set(true)

    const url = `${environment.apiUrl}/family/${this.me$().familyId}/shopping`
    return this.httpClient.put<TShoppingList>(url, newShoppingList)
      .pipe(
        tap((value) => {
          // Update family locally and stop loading
          const family = this.pFamily$.value!
          family.shoppingList = value
          this.pFamily$.next(family)

          this.isUpdatingFamily$.set(false)
        }),
        catchError(() => {
          this.isUpdatingFamily$.set(false)
          return NEVER
        })
      )
  }

  public switchUser(id: string): void {
    const user: User | undefined = this.familyMembers$()
      .find((u) => u.id === id)
    if (user) {
      this.pSelectedUser$.next(user)
    }
  }

  public updateFamilySchedule(newSchedule: FamilyMenuDay[]): void {
    this.family$
      .pipe(filter(Boolean), first())
      .subscribe((f) => {
        f.schedule = newSchedule
        this.pFamily$.next(f)
      })
  }
}
